// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-shared-list-card-___entity-card__date-range{display:flex}
`, "",{"version":3,"sources":["webpack://./src/components/shared/list-card/entity-card.scss"],"names":[],"mappings":"AAGA,4DACI,YAAa","sourcesContent":["@import '../../../styles/vars';\n@import '../../../styles/mixins';\n\n.date-range {\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date-range": `src-components-shared-list-card-___entity-card__date-range`,
	"dateRange": `src-components-shared-list-card-___entity-card__date-range`
};
export default ___CSS_LOADER_EXPORT___;
