import { BASE_URL } from '@constants/endpoints';
import axios from 'axios';
import { fetchConfig } from '@components/config/actions';
import { getServerErrorAction } from '@actions/shared-actions';

export const updateBatch = batch => {
  const request = axios.put(
    `${BASE_URL}/api/user_config/batch`,
    { data: batch }
  );
  return dispatch =>
    request.then(
      () => {
        dispatch(fetchConfig());
      },
      error => dispatch(getServerErrorAction('batch', error))
    );
};

export const updateTime = time => {
  const request = axios.put(
    `${BASE_URL}/api/user_config/time`,
    { data: time }
  );
  return dispatch =>
    request.then(
      () => {
        dispatch(fetchConfig());
      },
      error => dispatch(getServerErrorAction('time', error))
    );
};

export const updateDisplayTimeComponent = display_time => {
  const request = axios.put(
    `${BASE_URL}/api/user_config/display_time_component`,
    { data: display_time }
  );
  return dispatch =>
    request.then(
      () => {
        dispatch(fetchConfig());
      },
      error => dispatch(getServerErrorAction('display_time_component', error))
    );
};
