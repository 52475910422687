/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTime, updateDisplayTimeComponent } from '@components/settings/actions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import './settings.scss';

const Time = () => {
  const { global: { time, display_time_component } } = useSelector(state => state.config);
  const dispatch = useDispatch();

  const onFormatChange = event => dispatch(updateTime(event.target.value));
  const onDisplayComponentChange = event => dispatch(updateDisplayTimeComponent(event.target.value));

  return (
    <div styleName="settings-form-container">
      <div styleName="title">
        <div styleName="text">Time Display</div>
      </div>
      <div styleName="hint">
        Switch time format.
      </div>
      <div styleName="form">
        <FormControl component="fieldset">
          <RadioGroup aria-label="time" name="time" value={time} onChange={onFormatChange}>
            <FormControlLabel value="12" control={<Radio color="primary" />} label="12 Hour Format" />
            <FormControlLabel value="24" control={<Radio color="primary" />} label="24 Hour Format" />
          </RadioGroup>
        </FormControl>
      </div>
      <div styleName="hint">
        Display time component in trays.
      </div>
      <div styleName="form">
        <FormControl component="fieldset">
          <RadioGroup aria-label="displayTime" name="displayTime" value={display_time_component} onChange={onDisplayComponentChange}>
            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default memo(Time);
