import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime } from 'react-intl';
import moment from 'moment-timezone';
import { isMilitaryTime } from '@constants/config';

const DefaultDatetime = ({
  value,
  dateFormat = 'default',
  separator = '',
  ...other
}) => {
  const parsed = moment.isMoment(value) ? value : moment(value);
  return (
    parsed.isValid() &&
    <div>
      <FormattedDate day="numeric" month="numeric" year="numeric" value={parsed} format={dateFormat} {...other} />{separator}&nbsp;
      <FormattedTime hour="numeric" minute="numeric" hour12={!isMilitaryTime()} value={parsed} {...other} />
    </div>
  );
};

DefaultDatetime.propTypes = {
  dateFormat: PropTypes.string,
  separator: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default memo(DefaultDatetime);
